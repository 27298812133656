import React from 'react'
import { useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import '../styles/global.css'
import { changeLocale,useIntl } from "gatsby-plugin-intl"
import Layout from '../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,
        faLinkedin
    } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import Seo from "../components/seo";

export default function CV ({data}) {
    const intl = useIntl();
    const content = useRef(data.contentfulStaff.bodyEn)

    useEffect(()=>{
      if(intl.locale !=="en"){
          changeLocale(intl.locale);
      }
      switch(intl.locale){
          case "hy":
              //adding Hy Font:
              addHyFont();
              content.current = data.contentfulStaff.bodyHy;
              break;
          case "ru":
              // removing possible Hy and Fa fonts and add Latin font:
              addLatinFont();
              content.current = data.contentfulStaff.bodyRu; 
              break; 
          case "fa":
              //adding Fa Font:
              addFaFont(); 
              content.current = data.contentfulStaff.bodyFa; 
              break; 
          case "fr":
              // removing possible Hy and Fa fonts and add Latin font:
              addLatinFont();
              content.current = data.contentfulStaff.bodyFr;
              break;
          default:
              // removing possible Hy and Fa fonts and add Latin font:
              addLatinFont();
              content.current = data.contentfulStaff.bodyEn; 
              break; 
      }
  },[
      intl.locale, 
      data.contentfulStaff.bodyEn,
      data.contentfulStaff.bodyRu,
      data.contentfulStaff.bodyHy,
      data.contentfulStaff.bodyFa,
      data.contentfulStaff.bodyFr,
  ])

  function addHyFont() {
    if(document !== "undefined"){ //stop the build from failing 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("HyFont");
      }
    }
  }

  function addFaFont() {
    if(document !== "undefined"){ //stop the build from failing 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("FaFont");
      }
    }
  }

  function addLatinFont() {
    if(document !== "undefined"){ //stop the build from failing
      // First removing possible Hy and Fa Font:
      if(document.querySelector(".HyFont")){
        const content = document.querySelector(".HyFont");
        content.classList.remove("HyFont");
      }
      if(document.querySelector(".FaFont")){
        const content = document.querySelector(".FaFont");
        content.classList.remove("FaFont");
      }
      // Now adding the Latin font: 
      if(document.querySelector(".content-body")){
        const content = document.querySelector(".content-body");
        content.classList.add("LatinFont");
      }
    }
  }

    return (          
        <Layout 
        title={intl.formatMessage({id: `${data.contentfulStaff.name}`})} 
        isHome={false}>
            <div className='container'> 
                <section className="staff-page">   
                    <div className="post-card">
                        <header className="post-card-header">
                            <GatsbyImage
                                className="post-feature-image"
                                image={getImage(data.contentfulStaff.profilePic)}
                                />
                        </header>
                        <footer className="post-card-footer">
                            <div className="post-card-footer-right">
                                <div className="post-card-avatar">
                                    <a 
                                    href={`mailto:${data.contentfulStaff.email}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    className="author-profile-image">
                                        <FontAwesomeIcon 
                                        icon={faEnvelope} 
                                        alt="Email"
                                        size='lg'/>
                                    </a>
                                    <a 
                                    href={`${data.contentfulStaff.linkedinLink}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    className="author-profile-image">
                                        <FontAwesomeIcon 
                                        icon={faLinkedin} 
                                        alt="LinkedIn"
                                        size='lg'/>
                                    </a>
                                    <a
                                    href={`${data.contentfulStaff.facebookLink}`}
                                    target='_blank'
                                    rel='noreferrer'
                                    className="author-profile-image">
                                        <FontAwesomeIcon  
                                        icon={faFacebook} 
                                        alt="Facebook"
                                        size='lg'/>
                                    </a>            
                                </div>
                            </div>
                        </footer>
                    </div>
                    <section>
                        <h1 className="post-card-title">{intl.formatMessage({id: `${data.contentfulStaff.name}`})}</h1>
                        <h2 className="post-card-title">{intl.formatMessage({id: `${data.contentfulStaff.position}`})}</h2>
                        <section className="content-body">
                            {renderRichText(content.current)}
                        </section>
                    </section>
                </section>
            </div>
        </Layout>
    )
}

export const Head = ({ data, pageContext }) => (
    <Seo
    title={`${pageContext.intl.messages[`${data.contentfulStaff.name}`]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
    description={pageContext.intl.messages[`${data.contentfulStaff.name}`]}
    article={false}>
    </Seo>
  )

export const staffCvQuery = graphql`
query ($slug: String!) {
    contentfulStaff(slug: {eq: $slug}) {
      name
      position
      facebookLink
      linkedinLink
      email
      bodyEn {
        raw
      }
      bodyFa {
        raw
      }
      bodyFr {
        raw
      }
      bodyHy {
        raw
      }
      bodyRu {
        raw
      }
      profilePic {
        gatsbyImageData
      }
    }
  }  
`;